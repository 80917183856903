import React from "react";
import {Layout} from "antd";
const {Footer} = Layout;

export default class FooterBar extends React.Component {
    render() {
        return (
            <Footer style={{textAlign: 'center', backgroundColor:"#343a40", color:"white"}}>

            </Footer>
        )
    }
}
