import React from "react";


export default class Divider extends React.Component {
    render() {
        return (
            <div className={"Divider"}/>
        )
    }
}
