import React from "react";
import {Empty} from "antd";

export default class Home extends React.Component {
    render() {
        return (
            <div>
                <Empty/>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed consectetur turpis quis massa pharetra ornare. In accumsan aliquam feugiat. Sed consectetur mattis odio, a mattis tortor. Nam feugiat nisl arcu, quis consequat sem eleifend quis. Nam feugiat, libero vitae bibendum sollicitudin, enim neque efficitur eros, et imperdiet ligula nisi vitae neque. Praesent eu nisl magna. Quisque mattis ex quis sagittis vulputate. Phasellus dapibus libero id blandit sollicitudin. Vestibulum gravida convallis nunc ut rutrum. Donec tempor eros id nulla semper, at cursus ex iaculis. Cras scelerisque ultricies sapien ac sodales. Donec a odio nisi. Maecenas a urna leo. Phasellus quam nunc, elementum ut orci gravida, fermentum pellentesque mauris. Quisque dignissim, purus non malesuada volutpat, nulla lectus viverra ex, nec vestibulum arcu odio at turpis. Ut metus justo, ornare ac ante ut, dapibus blandit justo.

                Ut vulputate velit id efficitur rutrum. Proin luctus viverra dui sed scelerisque. Duis felis sapien, dictum eget lectus sit amet, pretium scelerisque nisi. Donec urna libero, egestas ac leo sit amet, facilisis ultrices massa. In nec metus ac est aliquam tincidunt. Maecenas in sem eu quam vehicula ornare. Nullam sit amet risus eget sapien pulvinar dignissim. Nam accumsan felis et ligula elementum, quis maximus elit tincidunt.

                Nulla molestie, mi eget ultricies rutrum, est ante vulputate mauris, sit amet fermentum leo odio sit amet sem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla vitae odio elit. Vestibulum consequat commodo fringilla. Phasellus venenatis ligula sed neque mollis, eget volutpat velit placerat. Morbi egestas tincidunt magna sagittis aliquet. Cras ac suscipit diam. Cras lacinia semper malesuada.

                Sed imperdiet dolor et erat vestibulum, quis euismod turpis bibendum. Integer sodales augue vestibulum ex mollis convallis. Nullam aliquam quam ut tincidunt bibendum. Suspendisse porttitor lacus in bibendum condimentum. Curabitur purus sem, scelerisque vel dui tristique, tincidunt finibus tellus. In hac habitasse platea dictumst. Nunc maximus, mi sed bibendum auctor, ex ex posuere felis, at aliquam est metus vitae enim. Vivamus scelerisque nisl ut lacus feugiat, a dignissim massa pellentesque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris a rhoncus quam, ac iaculis magna. Mauris justo felis, tempor et viverra ullamcorper, imperdiet sit amet tortor. Ut porttitor feugiat arcu, vel placerat elit finibus ut. Sed auctor mi in nunc semper, id accumsan metus tempus. Nulla tristique vestibulum hendrerit.

                Phasellus fringilla odio ut dui porttitor egestas. In varius bibendum elit, id rutrum dui scelerisque eu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse convallis mi a odio dictum dapibus. Fusce et eleifend ante. Donec sit amet nisi ante. Mauris hendrerit turpis nunc, sed rhoncus lacus consectetur in. Aenean in accumsan ex.

                Vestibulum efficitur eu risus a ultrices. Nulla vulputate pretium cursus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In suscipit, ipsum ac finibus pulvinar, mauris arcu suscipit libero, volutpat interdum diam ipsum vitae nisl. Duis finibus metus risus, pharetra pharetra tellus hendrerit in. Fusce risus quam, tempus in turpis commodo, feugiat placerat purus. Nam consequat pulvinar mauris nec elementum. Duis ut eros vitae augue hendrerit rutrum. Proin sed nisi eget nibh consectetur fringilla.

                Maecenas efficitur eget lacus finibus lacinia. Vestibulum posuere odio ac quam egestas, vitae congue urna sodales. Donec pulvinar eget lorem sit amet suscipit. Phasellus vitae enim eget lorem placerat gravida vel ac lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam erat volutpat. Vestibulum felis tortor, dictum vehicula erat porttitor, mollis dictum augue. Nunc non libero in massa hendrerit feugiat. Morbi finibus lectus id ligula ultrices sodales. Integer ullamcorper hendrerit diam, id elementum purus tempus efficitur. Nulla at tellus vitae nibh maximus placerat ac et nunc. Etiam sollicitudin mi vel mi semper consequat.

                Nam sed dictum nisl. Pellentesque neque lacus, pellentesque et nulla finibus, volutpat pulvinar est. Nullam vitae nulla elit. Phasellus a dignissim neque, vitae fermentum nisl. Sed scelerisque at felis nec euismod. Phasellus interdum neque nec odio malesuada consequat. Fusce suscipit dignissim dui, sit amet auctor purus commodo pretium. Nam porta rhoncus nibh, id cursus justo mollis vel. Nunc interdum enim id lacus facilisis dignissim. Sed nec commodo arcu, at porta erat. Nunc aliquam neque scelerisque, tempus turpis in, imperdiet dolor. In hac habitasse platea dictumst. Morbi odio augue, tempus sed leo vel, dapibus porttitor arcu. Aenean sapien nisi, efficitur et finibus nec, vestibulum sed erat. Sed venenatis tempor libero ut hendrerit. In dictum arcu leo, vitae accumsan odio laoreet nec.

                Quisque odio magna, tincidunt ac libero nec, sagittis pulvinar libero. Aliquam dignissim laoreet sagittis. Nunc ante neque, dapibus eu dui a, molestie congue libero. In dapibus sapien eros, et vulputate ipsum condimentum vitae. Fusce ipsum risus, auctor id venenatis sit amet, bibendum id velit. Pellentesque commodo justo at urna finibus, at congue justo vehicula. Suspendisse imperdiet, ipsum eu bibendum lobortis, lectus nisi tincidunt ligula, sed pretium ex ligula eget nisl. Nulla a massa aliquet, dapibus quam id, mattis diam. Nam dapibus dolor a dignissim aliquam. Donec nec dolor a sapien consectetur cursus. Aliquam vulputate faucibus leo, nec iaculis augue. Mauris sollicitudin ante id nulla fringilla rhoncus vitae at erat. Phasellus risus sapien, lacinia nec scelerisque ac, suscipit sed quam. Praesent interdum lectus quis diam semper sagittis. Nulla eu neque ipsum. Nam neque turpis, egestas non velit eu, pulvinar suscipit magna.

                Fusce sit amet malesuada ligula. Maecenas et ullamcorper dui, id malesuada mi. In et risus in justo porta maximus. Etiam et nibh laoreet, lacinia velit at, tincidunt nisi. Etiam convallis congue dictum. Vivamus vel mauris at sapien tempus eleifend. Sed laoreet et sapien vel posuere. Duis non felis id felis vehicula varius. Suspendisse dignissim egestas lorem, eget malesuada sem vulputate ut. Maecenas ac cursus arcu. Proin pellentesque mauris sed nisi euismod imperdiet.
            </div>
        )
    }
}
